import { BehaviorSubject } from 'rxjs';
import { ObjectUtils } from 'src/app/utils/object';
import { ProjectUpdateAction } from '../../enum/project/project-update-action';
import { IProjectUpdate } from '../../types/project/project-update';

export class NBMD {
    private data: any;
    private tags: Map<string, string> = new Map<string, string>();

    updates$: BehaviorSubject<IProjectUpdate> = new BehaviorSubject<IProjectUpdate>(undefined);

    private seperator: string;

    constructor(seperator?: string) {
        this.seperator = ((seperator) ? seperator : '.');
    }

    public get(pathOrTag: string): any {
        return this.navigateTo(pathOrTag);
    }

    public set(pathOrTag: string, value: any): void {
        let current = this.navigateTo(pathOrTag);
        const prev = current;
        current = value;
        this.updates$.next({ path: this.resolve(pathOrTag), action: ProjectUpdateAction.Update, newVal: current, prevVal: prev });
    }

    public registerTag(tag: string, path: string): void {
        this.tags.set(tag, path);
        this.updates$.next({ path: 'tags', action: ProjectUpdateAction.Add, newVal: path });
    }

    public toStringData(): string {
        return JSON.stringify(this.data);
    }

    public toString(): string {
        const temp = ObjectUtils.cloneObject(this);
        delete temp.updates$;
        return JSON.stringify(temp);
    }

    private navigateTo(pathOrTag: string): any {
        const pathSplit = this.resolve(pathOrTag).split(this.seperator);

        // Run through and build path if not existent
        let current = this.data;
        for (let i = 0; i < pathSplit.length; i++) {
            if (current[pathSplit[i]]) {
                current[pathSplit[i]] = {};
            }
            current = current[pathSplit[i]];
        }
    }

    private resolve(pathOrTag: string): string {
        let path: any = pathOrTag;
        if (this.tags.has(path)) {     // If tag, get tag path.
            path = this.tags.get(path);
        }
        return path;
    }
}
