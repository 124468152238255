import { Injectable, NgZone, OnDestroy } from '@angular/core';
import * as THREE from 'three';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Type } from 'src/app/utils/type';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { IResizeInfo } from 'src/app/model/types/3dview/resize-info';
import { CanvasStore } from 'src/app/public/canvas-store';
import { InputHandlerService } from './input-handler.service';
import { SceneStore } from 'src/app/public/scene-store';
import { app_colors } from 'src/app/model/config/app-theme';
import { View3DEventsService } from './view3d-events.service';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';

@Injectable({
    providedIn: 'root'
})
export class ThreeRendererService implements OnDestroy {
    public readonly threeID$: ReplaySubject<string> = new ReplaySubject<string>(1);

    private canvas: HTMLCanvasElement;
    private canvasParent: HTMLElement;
    public renderer: THREE.WebGLRenderer;

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private ngZone: NgZone,
        private inputHandler: InputHandlerService,
        private threeEvents: View3DEventsService
    ) {
        this.threeID$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull()
        ).subscribe((id: string) => {
            this.canvas = CanvasStore.getCanvas(id);
            this.canvasParent = CanvasStore.getCanvasParent(id);

            this.setupRenderer(this.canvas);

            RenderTrigger.resize$.pipe(
                takeUntil(this.destroy$),
                filter((value: string) => value === id)
            ).subscribe((options?: string) => {
                this.setSize(this.canvasParent.clientWidth, this.canvasParent.clientHeight);
            });

            RenderTrigger.enableXR$.pipe(
                takeUntil(this.destroy$)
            ).subscribe((enabled: boolean) => {
                RenderTrigger.enableRenderingLoop$.next(enabled);
            });

            this.threeEvents.setup3DViewFinished$.pipe(
                takeUntil(this.destroy$),
                RXJSUtils.filterFalse(),
                take(1)
            ).subscribe((_: boolean) => {
                RenderTrigger.render$.pipe(
                    takeUntil(this.destroy$),
                    filter((value: string) => value === id)
                ).subscribe((threeID: string) => {
                    this.render(SceneStore.getScene(threeID), this.inputHandler.getMainCamera());
                });

                RenderTrigger.screenshotTrigger$.pipe(
                    takeUntil(this.destroy$),
                    filter((value: string) => value === id)
                ).subscribe(() => {
                    RenderTrigger.screenshotOutput$.next(this.getScreenShot());
                });

                RenderTrigger.enableRenderingLoop$.pipe(
                    takeUntil(this.destroy$)
                ).subscribe((enabled: boolean) => {
                    if (enabled) {
                        this.renderer.setAnimationLoop(this.renderLoop.bind(this));
                    } else {
                        this.renderer.setAnimationLoop(null);
                    }
                });
            });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    private renderLoop(): void {
        this.threeID$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull(),
            take(1)
        ).subscribe((id: string) => {
            RenderTrigger.render$.next(id);
            this.inputHandler.updateControls();
        });
    }

    setupRenderer(canvas: HTMLCanvasElement): void {
        this.renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: true,
        });

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(app_colors.background_color);
    }

    setSize(width: number, height: number) {
        console.log(`Resize to: { x: ${ width }, y: ${ height } } `);

        this.renderer.setSize(width, height, false);
        //this.canvas.setAttribute('aspect-ratio', `${ width } / ${ height }`);
    }

    render(scene: THREE.Scene, camera: THREE.PerspectiveCamera): void {
        if (!scene || !camera) {
            console.error('Scene or camera not defined! Scene: ', scene, ' | Camera: ', camera);
            return;
        }

        // Run rendering outside angular to avoid change detection
        this.ngZone.runOutsideAngular(() => {
            this.renderer.render(scene, camera);
        });
    }

    getScreenShot(): HTMLImageElement {
        const img = new Image();
        try {
            img.src = this.renderer.domElement.toDataURL();
        } catch (e) {
            console.log(e);
            return;
        } finally {
            return img;
        }
    }

    kill() {
        this.renderer.dispose();
    }
}
