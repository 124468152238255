import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import * as THREE from 'three';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ThreeRendererService } from 'src/app/services/3dview/three-renderer.service';
import * as uuid from 'uuid';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { threeHandlerModeConfig } from 'src/app/model/config/3dview/threemode-handler-config';
import { ThreeHandler } from 'src/app/model/classes/3dview/three-handler';
import { ThreeMode } from 'src/app/model/enum/3dview/three-mode';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { take, takeUntil } from 'rxjs/operators';
import { IResizeInfo } from 'src/app/model/types/3dview/resize-info';
import { CanvasStore } from 'src/app/public/canvas-store';
import { InputHandlerService } from 'src/app/services/3dview/input-handler.service';
import { SceneStore } from 'src/app/public/scene-store';
import { View3DEventsService } from 'src/app/services/3dview/view3d-events.service';
import { SelectionHandlerService } from 'src/app/services/3dview/selection-handler.service';
import { InteractionHandlerService } from 'src/app/services/3dview/interaction-handler.service';

@Component({
    selector: 'app-three-view',
    templateUrl: './three-view.component.html',
    styleUrls: ['./three-view.component.scss'],
    providers: [
        ThreeRendererService,
        InputHandlerService,
        SelectionHandlerService,
        View3DEventsService,
        InteractionHandlerService
    ]
})
export class ThreeViewComponent implements OnInit, OnDestroy, AfterViewInit {
    ID: string;

    @Input() mode: ThreeMode;

    threeHandler$: BehaviorSubject<ThreeHandler> = new BehaviorSubject<ThreeHandler>(undefined);

    private canvas: HTMLCanvasElement;
    private canvasParent: HTMLElement;

    @Output() emitImage?: EventEmitter<string> = new EventEmitter();

    destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private ngZone: NgZone,
        private threeRenderer: ThreeRendererService,
        public inputHandler: InputHandlerService,
        private selectionHandler: SelectionHandlerService,
        private interactionHandlerService: InteractionHandlerService,
        private threeEvents: View3DEventsService
    ) {
        this.ID = uuid.v4();
        SceneStore.addScene(this.ID, new THREE.Scene());

        this.threeEvents.sceneBuilt$.next(true);
    }

    ngOnInit() {
        this.threeHandler$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull(),
            take(1)
        ).subscribe((handler: ThreeHandler) => {
            handler.init();
            this.threeEvents.threeHandlerInitialized$.next(true);
        });
    }

    ngAfterViewInit() {
        const { canvas, parent } = CanvasStore.addCanvas(
            this.ID,
            document.getElementById(this.ID) as HTMLCanvasElement,
            document.getElementById('canvas_parent')
        );
        this.canvas = canvas;
        this.canvasParent = parent;

        CanvasStore.addCanvas(this.ID, this.canvas, this.canvasParent);

        this.threeEvents.canvasFetched$.next(true);

        this.threeRenderer.threeID$.next(this.ID);
        this.inputHandler.threeID$.next(this.ID);
        this.selectionHandler.threeID$.next(this.ID);
        this.interactionHandlerService.threeID$.next(this.ID);

        console.log(this.mode);

        const objType = threeHandlerModeConfig.get(this.mode).type;
        this.threeHandler$.next(new objType(
            this.ID,
            this.threeRenderer,
            this.inputHandler,
            this.threeEvents
        ));

        this.threeEvents.threeHandlerConstructed$.next(true);

        this.threeHandler$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull(),
            take(1)
        ).subscribe((handler: ThreeHandler) => {
            handler.afterViewInit();
            this.threeEvents.threeHandlerAfterViewInitialized$.next(true);
        });

        // Run rendering outside angular to avoid change detection
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('resize', this.onResize.bind(this));
        });

        this.onResize();

        this.threeEvents.setup3DViewFinished$.next(true);
    }

    ngOnDestroy() {
        this.destroy$.next(true);

        this.threeHandler$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull(),
            take(1)
        ).subscribe((handler: ThreeHandler) => {
            handler.destroy();
        });
    }

    onResize() {
        RenderTrigger.resize$.next(this.ID);

        RenderTrigger.render$.next(this.ID);
    }
}
