import * as THREE from 'three';
import { ThreeRendererService } from 'src/app/services/3dview/three-renderer.service';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { ReplaySubject } from 'rxjs';
import { CanvasStore } from 'src/app/public/canvas-store';
import { SceneStore } from 'src/app/public/scene-store';
import { InputHandlerService } from 'src/app/services/3dview/input-handler.service';
import { NgZone } from '@angular/core';
import { View3DEventsService } from 'src/app/services/3dview/view3d-events.service';
import { SelectionHandlerService } from 'src/app/services/3dview/selection-handler.service';

export class ThreeHandler {

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private ID: string,
        private ngZone: NgZone,
        private threeRenderer: ThreeRendererService,
        private inputHandler: InputHandlerService,
        private selectionHandler: SelectionHandlerService,
        private threeEvents: View3DEventsService,
    ) {}

    init(): void {
        //
    }

    afterViewInit(): void {
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshLambertMaterial({color: 0x00ff00});
        const cube = new THREE.Mesh(geometry, material);
        this.scene.add(cube);

        const ambient = new THREE.AmbientLight(0xffffff, 0.3);
        this.scene.add(ambient);

        const directional = new THREE.DirectionalLight(0xffffff, 0.35);
        directional.position.set(2, 3, 2);
        this.scene.add(directional);
        const spot = new THREE.SpotLight(0xffffff, 0.35);
        spot.angle = Math.PI / 20;
        spot.penumbra = 0.5;
        spot.position.set(-2, 3, 2);
        this.scene.add(spot);

        RenderTrigger.render$.next(this.ID);
    }

    destroy(): void {
        this.destroy$.next(true);
    }

    render(): void {
        RenderTrigger.render$.next(this.ID);
    }

    getID(): string {
        return this.ID;
    }

    public get canvas(): HTMLCanvasElement {
        return CanvasStore.getCanvas(this.ID);
    }

    public get scene(): THREE.Scene {
        return SceneStore.getScene(this.ID);
    }

    public get camera(): THREE.PerspectiveCamera {
        return this.inputHandler.getMainCamera();
    }
}
