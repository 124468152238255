import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Events } from './public/events';
import { ProcessingOverlayService } from './services/processing-overlay.service';

const processingOverlayUrls: string[] = [
    'URL_HERE'
];

const icons: { id: string, path: string }[] = [
    { id: 'navbar_network', path: '../assets/network.svg' },
    { id: 'window_maximize', path: '../assets/maximize.svg' },
    { id: 'window_minimize', path: '../assets/minimize.svg' },
    { id: 'window_close', path: '../assets/close.svg' }
];

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    development: boolean;

    showLoadingOverlay = false;

    constructor(
        private router: Router,
        public poService: ProcessingOverlayService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private cd: ChangeDetectorRef
    ) {
        this.development = environment.development;

        this.registerIcons();

        this.subscribeToRouterEvents();
    }

    ngOnInit(): void {
        this.poService.isLoading$().subscribe((loading: boolean) => {
            console.error('loading =>', loading);
            this.showLoadingOverlay = loading;

            // Angular wont update view, help it realize a change has occured.
            // Hides loading overlay!
            try {
              this.cd.detectChanges();
            } catch (e: any) {
              //
            }
        });
    }

    subscribeToRouterEvents(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.handleLoadingOverlay(event);
                Events.navStart$.next(event);
            }

            if (event instanceof NavigationEnd) {
                Events.navEnd$.next(event);
            }
        });
    }

    handleLoadingOverlay(event: any): void {
        if (this.shouldOverlayActivate(event.url)) {
            this.poService.activateOverlay();
        } else {
            this.poService.deactivateOverlay();
        }
    }

    shouldOverlayActivate(url: string): boolean {
        let activateOverlay = false;
        for (const key of processingOverlayUrls) {
            activateOverlay = activateOverlay || url.includes(key);
        }
        return activateOverlay;
    }

    registerIcons(): void {
        for (const icon of icons) {
            this.matIconRegistry.addSvgIcon(
                icon.id,
                this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
            );
        }
    }
}
