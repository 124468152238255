import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalstoreKey } from '../model/enum/localstore-key';

@Injectable({
  providedIn: 'root'
})
export class LocalstoreService {
  version: string = environment.version;

  constructor() {}

  get(key: string): any {
    const global = JSON.parse(localStorage.getItem(key));
    const session = JSON.parse(sessionStorage.getItem(key));

    return ((session) ? session : global);
  }

  set(key: LocalstoreKey, value: any): void {
    // Global
    localStorage.setItem(key, JSON.stringify(value));

    // Session
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
}
