import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WindowComponent } from '../components/gui/window/window.component';
import { WindowContentDirective } from '../directives/window-content.directive';
import { WindowDirective } from '../directives/window.directive';
import { MaterialModule } from './material.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,

        FormsModule,
        HttpClientModule,
        DragDropModule,
        OverlayModule,
        ReactiveFormsModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
    ],
    declarations: [
        //ProcessingOverlayComponent,
        //LoaderIconComponent,
        WindowDirective,
        WindowContentDirective,
        WindowComponent,
    ],
    exports: [
        // Modules
        FormsModule,
        HttpClientModule,
        DragDropModule,
        OverlayModule,
        ReactiveFormsModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,

        // Components
        //ProcessingOverlayComponent,
        //LoaderIconComponent,
        WindowDirective,
        WindowContentDirective,
        WindowComponent,
    ]
  })
  export class SharedModule {}
