import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WindowDirective } from 'src/app/directives/window.directive';
import { IWindowConfig } from 'src/app/model/types/window/window-config';
import { Type } from 'src/app/utils/type';

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss']
})
export class WindowComponent implements OnInit {

  maximized = false;

  @Input() parentID: string;

  @Input()
  get config(): IWindowConfig {
    return this._config;
  }
  set config(config: IWindowConfig) {
    this._config = config;

    // Load config once set
    if (Type.isDefined(config)) {
      this.loadComponent();
    }
  }
  private _config: IWindowConfig;

  @ViewChild(WindowDirective, {static: true}) appWindow!: WindowDirective;

  constructor() { }

  ngOnInit(): void {
    console.log('Window OnInit');
  }

  loadComponent() {
    //const viewContainerRef = this.appWindow.viewContainerRef;
    //viewContainerRef.clear();
    //const componentRef = viewContainerRef.createComponent(this.config.component);
    //if (typeof componentRef.instance.handleData === 'function') {
    //  componentRef.instance.handleData(this.config.data);
    //}
  }
}
