export class LoadStatus {
  status: boolean;
  title: string;
  msg: string;

  constructor(status: boolean = false, title: string = '', msg: string = '') {
    this.status = status;
    this.title = title;
    this.msg = msg;
  }
}

