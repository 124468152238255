import { Component, OnInit } from '@angular/core';
import { PagePath } from 'src/app/model/enum/pages';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() {}

  version: string = environment.version;

  PagePath = PagePath;

  ngOnInit(): void {
    //
  }
}
