import { Injectable } from '@angular/core';
import { Project } from '../model/classes/project/project';
import { LocalstoreKey } from '../model/enum/localstore-key';
import { LocalstoreService } from './localstore.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectManagerService {

    projects: Project[] = [];
    current: number;

    constructor(
        private localstore: LocalstoreService
    ) {
        const projects = this.localstore.get(LocalstoreKey.PROJECTS);
        const current = this.localstore.get(LocalstoreKey.CURRENT_PROJECT);
        if (projects) {
            for (const project of projects) {
                this.projects.push(project);
            }
        }
        this.current = ((current) ? current : 0);
        this.save();
    }

    public getActiveProject(): Project {
        return this.projects[this.current];
    }

    public new(): Project {
        const newProject = new Project();
        this.current = this.projects.push(newProject) - 1;
        this.save();
        return newProject;
    }

    public import(project: string): Project {
        const p = JSON.parse(project);
        this.current = this.projects.push(p) - 1;
        this.save();
        return p;
    }

    public export(project: Project): string {
        return JSON.stringify(project);
    }

    public load(ID: string): void {
        this.projects = this.localstore.get(LocalstoreKey.PROJECTS);
        this.current = this.projects.findIndex((project) => project.ID === ID);

        this.save();
    }

    public save(): void {
        this.localstore.set(LocalstoreKey.PROJECTS, this.projects);
        this.localstore.set(LocalstoreKey.CURRENT_PROJECT, this.current);
    }

    public delete(ID: string): void {
        this.projects = this.projects.filter((project) => project.ID !== ID);

        if (this.current < 0 || this.current >= this.projects.length) {
            this.current = this.projects.length - 1;
        }

        this.save();
    }

    public clear(): void {
        this.localstore.clear();
    }
}
