import { IProject } from '../../types/project/project';
import { NBMD } from '../../classes/nbmd/nbmd';

export const defaultProject: IProject = {
    ID: 'id',
    name: 'name',
    premise: 'premise',        // Story in a sentance
    setting: 'setting',        // Description of a place, time and social context the story builds upon.
    description: 'description',
    story: [],
    fabula: [],         // Key moments in order
    characters: [],
    relationships: [],
    notes: 'notes',
    metadata: new NBMD()
};
