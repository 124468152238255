export type Type<T = any> = new (...params: any[]) => T;

export namespace Type {
    /**
    * Detect whether object is of given type. (instanceof)
    *
    * @param obj - any - Object to check.
    * @param typeT - Type - Type to check for.
    *
    * @returns - boolean - True if obj is Sof given type, false otherwise.
    */
    export function isInstanceOf<T>(obj: any, typeT: Type<T>): boolean {
        return (obj instanceof typeT);
    }

    /**
    * Check whether objects are defined or not.
    *
    * @param obj - ...any[] - Object(s) to check.
    *
    * @returns - boolean - True if all are defined, false otherwise. E.g. (arg0 && arg1 && ...)
    */
    export function isDefined(...objs: any[]): boolean {
        // No arguments given, default false.
        if (objs.length === 0) {
            return false;
        }

        let result = true;
        for (const obj of objs) {
            result = result && (typeof obj !== 'undefined');
        }
        return result;
    }

    /**
    * Detect whether object(s) is/are null.
    *
    * @param obj - ...any[] - Object(s) to check.
    *
    * @returns - boolean - True if null, false otherwise.
    */
    export function isNull(...objs: any[]): boolean {
        return !notNull(objs);
    }

    /**
    * Detect whether object(s) is/are not null.
    *
    * @param obj - ...any[] - Object(s) to check.
    *
    * @returns - boolean - True if not null, false otherwise.
    */
    export function notNull(...objs: any[]): boolean {
        // No arguments given, default false.
        if (objs.length === 0) {
            return false;
        }

        let result = true;
        for (const obj of objs) {
            result = result && (obj != null);
        }

        return result;
    }

    /**
    * Detect whether object is defined or not.
    *
    * @param obj - any - Object to check.
    * @param type - string - Optional type to check for. **Default: "undefined"**
    *
    * @returns - boolean - True if defined, false otherwise.
    */
    export function isOfType(obj: any, type: string): boolean {
        return (typeof obj === type);
    }

    /**
    * Detect whether object is defined and not null.
    *
    * @param objs - ...any[] - Object(s) to check.
    *
    * @returns - boolean - True if defined and not null, false otherwise.
    */
    export function isDefined_NotNull(...objs: any[]): boolean {
        // No arguments given, default false.
        if (objs.length === 0) {
            return false;
        }

        return isDefined(...objs) && notNull(...objs);
    }

    /**
    * Checks whether given object has a property with given name.
    *
    * @param obj - any - Object to check.
    * @param property - string - Property name.
    *
    * @returns - boolean - "true" if present, "false" otherwise.
    */
    export function has(obj: any, property: string): boolean {
        return isDefined(obj[property]);
    }
}