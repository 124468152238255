import { ProjectEvents } from 'src/app/public/project-events';
import { ObjectUtils } from 'src/app/utils/object';
import { defaultProject } from '../../default/project/default-project';
import { ProjectUpdateAction } from '../../enum/project/project-update-action';
import { IProject } from '../../types/project/project';
import { StoryEventID } from '../../types/project/story-event-id';
import { NBMD } from '../nbmd/nbmd';
import { Character } from './character';
import { Relationship } from './relationship';
import { StoryEvent } from './story-event';
import * as uuid from 'uuid';

export class Project implements IProject {
    readonly ID: string;
    name: string;
    premise: string; // Story in a sentance
    setting: string; // Description of a place, time and social context the story builds upon.
    description: string;
    story: StoryEvent[];
    fabula: StoryEventID[]; // Key moments in order (if NOT multiple choice)
    characters: Character[];
    relationships: Relationship[];
    //languages
    notes: string;
    metadata: NBMD;

    constructor(project?: IProject) {
        const p = ObjectUtils.cloneObject(((project) ? project : defaultProject));
        for (const key of Object.keys(p)) {
            this[key] = p[key];
        }

        if (!project) {
            this.ID = uuid.v4();
        }
    }

    setName(name: string): void {
        const prev = this.name;
        this.name = name;
        ProjectEvents.emitUpdate('project(' + this.name + ').name', ProjectUpdateAction.Update, this.name, prev);
    }

    setPremise(premise: string): void {
        const prev = this.premise;
        this.premise = premise;
        ProjectEvents.emitUpdate('project(' + this.name + ').premise', ProjectUpdateAction.Update, this.premise, prev);
    }

    setSetting(setting: string): void {
        const prev = this.setting;
        this.setting = setting;
        ProjectEvents.emitUpdate('project(' + this.name + ').setting', ProjectUpdateAction.Update, this.setting, prev);
    }

    setDescription(description: string): void {
        const prev = this.description;
        this.description = description;
        ProjectEvents.emitUpdate('project(' + this.name + ').description', ProjectUpdateAction.Update, this.description, prev);
    }

    setNotes(notes: string): void {
        const prev = this.notes;
        this.notes = notes;
        ProjectEvents.emitUpdate('project(' + this.name + ').notes', ProjectUpdateAction.Update, this.notes, prev);
    }

    addStoryEvent(event: StoryEvent): void {
        this.story.push(event);
        ProjectEvents.emitUpdate('project(' + this.name + ').story', ProjectUpdateAction.Add, event);
    }

    removeStoryEvent(event: StoryEvent): void {
        const i = this.story.indexOf(event);
        if (i !== -1) {
            this.story.splice(i, 1); // Remove one of the specifed item, if found
            ProjectEvents.emitUpdate('project(' + this.name + ').story', ProjectUpdateAction.Remove, undefined, event);
        }
    }

    removeStoryEventByIndex(index: number): void {
        if (index >= 0 && index < this.story.length) {
            const storyEvents = this.story.splice(index, 1); // Remove one of the specifed item, if found
            ProjectEvents.emitUpdate('project(' + this.name + ').story', ProjectUpdateAction.Remove, undefined, storyEvents[0]);
        }
    }

    addFabula(id: StoryEventID): void {
        this.fabula.push(id);
        ProjectEvents.emitUpdate('project(' + this.name + ').fabula', ProjectUpdateAction.Add, id);
    }

    removeFabula(id: StoryEventID): void {
        const i = this.fabula.indexOf(id);
        if (i !== -1) {
            this.fabula.splice(i, 1); // Remove one of the specifed item, if found
            ProjectEvents.emitUpdate('project(' + this.name + ').fabula', ProjectUpdateAction.Remove, undefined, id);
        }
    }

    addCharacter(character: Character): void {
        this.characters.push(character);
        ProjectEvents.emitUpdate('project(' + this.name + ').characters', ProjectUpdateAction.Add, character);
    }

    removeCharacter(character: Character): void {
        const i = this.characters.indexOf(character);
        if (i !== -1) {
            this.story.splice(i, 1); // Remove one of the specifed item, if found
            ProjectEvents.emitUpdate('project(' + this.name + ').characters', ProjectUpdateAction.Remove, undefined, character);
        }
    }

    removeCharacterByIndex(index: number): void {
        if (index >= 0 && index < this.characters.length) {
            const characters = this.characters.splice(index, 1); // Remove one of the specifed item, if found
            ProjectEvents.emitUpdate('project(' + this.name + ').characters', ProjectUpdateAction.Remove, undefined, characters[0]);
        }
    }

    addRelationships(relationship: Relationship): void {
        this.relationships.push(relationship);
        ProjectEvents.emitUpdate('project(' + this.name + ').relationships', ProjectUpdateAction.Add, relationship);
    }

    removeRelationships(relationship: Relationship): void {
        const i = this.relationships.indexOf(relationship);
        if (i !== -1) {
            this.relationships.splice(i, 1); // Remove one of the specifed item, if found
            ProjectEvents.emitUpdate('project(' + this.name + ').relationships', ProjectUpdateAction.Remove, undefined, relationship);
        }
    }

    removeRelationshipsByIndex(index: number): void {
        if (index >= 0 && index < this.relationships.length) {
            const relationships = this.relationships.splice(index, 1); // Remove one of the specifed item, if found
            ProjectEvents.emitUpdate('project(' + this.name + ').relationships', ProjectUpdateAction.Remove, undefined, relationships[0]);
        }
    }
}
