import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ProjectManagerService } from 'src/app/services/project-manager.service';
import { Project } from '../../model/classes/project/project';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

    destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        public projectManager: ProjectManagerService
    ) {
        console.log(this.projectManager.projects, this.projectManager.current);
    }

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        //
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    new() {
        this.projectManager.new();
    }

    continue(ID?: string) {
        if (!ID) {
            ID = this.project.ID;
        }

        console.log('continue: ', ID);
    }

    import() {
        console.log('import!');
    }

    export(ID?: string) {
        if (!ID) {
            ID = this.project.ID;
        }

        console.log('export: ', ID);
    }

    delete(ID?: string) {
        if (!ID) {
            ID = this.project.ID;
        }

        this.projectManager.delete(ID);
    }

    get project(): Project {
        return this.projectManager.getActiveProject();
    }
}
