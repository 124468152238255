import { Component, OnInit } from '@angular/core';
import { IWindowConfig } from '../../model/types/window/window-config';

@Component({
  selector: 'app-story-editor-view',
  templateUrl: './story-editor-view.component.html',
  styleUrls: ['./story-editor-view.component.scss']
})
export class StoryEditorViewComponent implements OnInit {

  windowConfig: IWindowConfig = {
    component: undefined,
    data: undefined,
    title: 'Wooooooooooooooooooooooot!',
    enable: {
        maximize_minimize: true,
        close: true,
        titlebar: true
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

}
