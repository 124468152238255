import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { IResizeInfo } from '../model/types/3dview/resize-info';

// Helper class for easier trigger for rendering, resize and screenshot capture
export class RenderTrigger {
    public static readonly render$ = new ReplaySubject<string>(1);
    public static readonly resize$ = new ReplaySubject<string>(1);
    public static readonly screenshotTrigger$ = new Subject<string>();
    public static readonly screenshotOutput$ = new Subject<HTMLImageElement>();
    public static readonly enableRenderingLoop$ = new BehaviorSubject<boolean>(false);
    public static readonly enableXR$ = new BehaviorSubject<boolean>(false);
}
