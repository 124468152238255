<div class="app_background">
    
    <app-navbar id="tool_bar"></app-navbar>
    <div id="app_content_wrapper">
        <router-outlet></router-outlet>

        <!--<ng-container *ngIf="(poService.activateProcessingOverlay$ | async).status" style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0; margin: 0px; padding: 0px;">
            <app-processing-overlay [determinate]="false" [background]="'app_color'"></app-processing-overlay>
        </ng-container>-->
    </div>
</div>

<div *ngIf="development" class="developmentWatermark">
    <span>Development</span>
</div>