import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ThreeMode } from 'src/app/model/enum/3dview/three-mode';
import { IWindowConfig } from 'src/app/model/types/window/window-config';
import { ThreeViewComponent } from '../gui/three-view/three-view.component';

@Component({
    selector: 'app-relation-network-view',
    templateUrl: './relation-network-view.component.html',
    styleUrls: ['./relation-network-view.component.scss'],
})
export class RelationNetworkViewComponent implements OnInit, AfterViewInit, OnDestroy {

    ThreeMode = ThreeMode;

    @ViewChild(ThreeViewComponent) threeView: ThreeViewComponent;

    destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    windowConfig: IWindowConfig = {
        component: undefined,
        data: undefined,
        title: 'Wooooooooooooooooooooooot!',
        enable: {
            maximize_minimize: true,
            close: true,
            titlebar: true
        }
    };

    constructor() {}

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        //
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }
}
