<div class="grid-container">
    <div class="grid">
        <mat-card id="header">
            <h1 id="header_text">Projects</h1>
        </mat-card>
        <ng-container id="list_container">
            <mat-list role="list" id="list">
                <mat-list-item role="listitem" *ngFor="let project of projectManager.projects" style="height: max-content;">
                    <mat-card id="listitem">
                        <span id="project_title">{{project.name}}</span>
                        <span id="project_premise">{{project.premise}}</span>
                        <div id="project_actions">
                            <button mat-button id="project_delete" class="button_gray" (click)="delete(project.ID)">Delete</button>
                            <button mat-button id="project_continue" class="button" (click)="continue(project.ID)">Continue ></button>
                        </div>
                    </mat-card>
                </mat-list-item>
            </mat-list>
        </ng-container>
        <div id="action_buttons">
            <button mat-button style="width: auto; height: 5em; margin: 5px; grid-area: new;" class="button_large" (click)="new()">New project</button>
            <button mat-button style="width: auto; height: 5em; margin: 5px; grid-area: continue;" class="button_large" (click)="continue()">Continue last ></button>
            <button mat-button style="width: auto; height: 3em; margin: 5px; grid-area: import;" class="button_gray" (click)="import()">Import</button>
            <button mat-button style="width: auto; height: 3em; margin: 5px; grid-area: export;" class="button_gray" (click)="export()">Export</button>
        </div>
    </div>
</div>