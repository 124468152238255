import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './modules/material.module';
import { HomeComponent } from './components/home/home.component';
import { ThreeModule } from './modules/three.module';
import { SharedModule } from './modules/shared.module';
import { RelationNetworkViewComponent } from './components/relation-network-view/relation-network-view.component';
import { ProjectViewComponent } from './components/project-view/project-view.component';
import { StoryEditorViewComponent } from './components/story-editor-view/story-editor-view.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { SettingsViewComponent } from './components/settings-view/settings-view.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProjectDashboardComponent } from './components/dashboards/project-dashboard/project-dashboard.component';

@NgModule({
    imports: [
        AppRoutingModule,
        MaterialModule,
        SharedModule,
        ThreeModule,
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        RelationNetworkViewComponent,
        NavbarComponent,
        ProjectViewComponent,
        StoryEditorViewComponent,
        MapViewComponent,
        SettingsViewComponent,
        ProjectDashboardComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
