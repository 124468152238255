import { Injectable, OnDestroy } from '@angular/core';
import * as THREE from 'three';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { View3DEventsService } from './view3d-events.service';
import { InputHandlerService } from './input-handler.service';
import { List } from 'src/app/utils/list';
import { SceneStore } from 'src/app/public/scene-store';

@Injectable({
    providedIn: 'root'
})
export class SelectionHandlerService implements OnDestroy {

    public readonly threeID$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    private raycaster: THREE.Raycaster = new THREE.Raycaster();
    private mainCamera: THREE.PerspectiveCamera;

    public selected: List<THREE.Object3D> = new List<THREE.Object3D>();

    private handleClicks = true;
    private ignoreNRClicks = 0;

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private inputHandler: InputHandlerService,
        private threeEvents: View3DEventsService
    ) {
        this.threeID$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull(),
            take(1)
        ).subscribe((id: string) => {
            this.threeEvents.click$.pipe(
                takeUntil(this.destroy$),
                RXJSUtils.filterUndefinedAndNull()
            ).subscribe(this.onClick.bind(this));

            this.threeEvents.dragStart$.pipe(
                takeUntil(this.destroy$),
                RXJSUtils.filterUndefinedAndNull()
            ).subscribe(this.onDragStart.bind(this));

            this.threeEvents.dragEnd$.pipe(
                takeUntil(this.destroy$),
                RXJSUtils.filterUndefinedAndNull()
            ).subscribe(this.onDragEnd.bind(this));
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    private onClick(mouse: THREE.Vector2) {
        const ignoreClicks = this.ignoreNRClicks > 0;
        console.log('handleClicks: ', this.handleClicks, '  |  ignore clicks: ', ignoreClicks);
        
        if (!this.handleClicks || ignoreClicks) {

            if (ignoreClicks) { this.ignoreNRClicks--; }
            return;
        }

        this.raycaster.setFromCamera(mouse, this.inputHandler.getMainCamera());

        const intersects = this.raycaster.intersectObjects(SceneStore.getScene(this.threeID$.getValue()).children, true);
        console.log(intersects);

        /*if (intersects.length > 0) {
            if (INTERSECTED != intersects[0].object) {
                if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

                INTERSECTED = intersects[0].object;
                INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
                INTERSECTED.material.emissive.setHex(0xff0000);
            }
        } else {

            if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

            INTERSECTED = null;

        }*/
    }

    private onDragStart(event: Event) {
        this.handleClicks = false;
    }

    private onDragEnd(event: Event) {
        this.handleClicks = true;
        this.ignoreNRClicks = 1;
    }
}
