<nav id="nav">
    <a class="logo_link" style="cursor: pointer;" [routerLink]="[PagePath.HOME]">
        <img class="logo_image" src="../../../assets/app-logo.svg"/>
    </a>

    <mat-list id="nav_list" role="list">
        <mat-list-item role="listitem" [routerLinkActive]="['active']" [routerLink]="[PagePath.PROJECT]">
            <div class="nav_bar_item">
                <mat-icon svgIcon="navbar_network"></mat-icon>
                <p>Project</p>
            </div>
        </mat-list-item>

        <mat-list-item role="listitem" [routerLinkActive]="['active']" [routerLink]="[PagePath.STORY_EDITOR]">
            <div class="nav_bar_item">
                <mat-icon svgIcon="navbar_network"></mat-icon>
                <p>Story<br>Editor</p>
            </div>
        </mat-list-item>

        <mat-list-item role="listitem" [routerLinkActive]="['active']" [routerLink]="[PagePath.RELATION_NETWORK]">
            <div class="nav_bar_item">
                <mat-icon svgIcon="navbar_network"></mat-icon>
                <p>Network</p>
            </div>
        </mat-list-item>

        <!--<mat-list-item role="listitem" [routerLinkActive]="['active']" [routerLink]="[PagePath.MAP]">
            <div class="nav_bar_item">
                <mat-icon svgIcon="navbar_network"></mat-icon>
                <p>Map</p>
            </div>
        </mat-list-item>-->

        <!--<mat-list-item role="listitem" [routerLinkActive]="['active']" [routerLink]="[PagePath.SETTINGS]">
            <div class="nav_bar_item">
                <mat-icon svgIcon="navbar_network"></mat-icon>
                <p>Settings</p>
            </div>
        </mat-list-item>-->
    </mat-list>
    
    <div id="version_wrapper">
        <mat-list role="list">
            <mat-list-item role="listitem" class="nav_bar_item">
                <p style="margin-bottom: 10px;">Version:</p>
                <p>{{version}}</p>
            </mat-list-item>
        </mat-list>
    </div>
</nav>