declare var require: any;
export class ObjectUtils {
    static _ = require('lodash');

    /**
     * @param object // to clone
     * @returns cloned object
     */
    static cloneObject(object: any): any {
        return this._.cloneDeep(object);
    }

    static sortyBy(array: any[]): any[] {
        return this._.sortBy(array);
    }

    static isEqual(a: any, b: any): boolean {
        return this._.isEqual(a, b);
    }
}
