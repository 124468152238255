import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThreeViewComponent } from '../components/gui/three-view/three-view.component';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule
    ],
    declarations: [
        ThreeViewComponent,
    ],
    exports: [
        ThreeViewComponent,
    ]
  })
  export class ThreeModule {}
