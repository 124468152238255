import { Injectable, OnDestroy } from '@angular/core';
import * as THREE from 'three';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { View3DEventsService } from './view3d-events.service';
import { take, takeUntil } from 'rxjs/operators';
import { CanvasStore } from 'src/app/public/canvas-store';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';

@Injectable({
    providedIn: 'root'
})
export class InteractionHandlerService implements OnDestroy {

    public readonly threeID$: ReplaySubject<string> = new ReplaySubject<string>(1);

    private mousedown = false;
    private mouseup = false;
    private mousemove = false;
    private mouseMovedWhileClicked = false;

    private lastMouseDownEvent: any;
    private lastMouseUpEvent: any;
    private lastMouseMoveEvent: any;

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private threeEvents: View3DEventsService
    ) {
        this.threeID$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull(),
            take(1)
        ).subscribe((id: string) => {
            const canvas = CanvasStore.getCanvas(id);

            window.addEventListener('onmousedown', this.onMouseDown.bind(this));
            window.addEventListener('onmouseup', this.onMouseUp.bind(this));
            window.addEventListener('onmousemove', this.onMouseMove.bind(this));

            this.destroy$.pipe(take(1)).subscribe((_: boolean) => {
                window.removeEventListener('onmousedown', this.onMouseDown.bind(this));
                window.removeEventListener('onmouseup', this.onMouseUp.bind(this));
                window.removeEventListener('onmousemove', this.onMouseMove.bind(this));
            });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    private onMouseDown(event): void {
        console.log(event);
        this.handleEvent('mousedown', event);
    }

    private onMouseUp(event): void {
        console.log(event);
        this.handleEvent('mouseup', event);
    }

    private onMouseMove(event): void {
        console.log(event);
        this.handleEvent('mousemove', event);
    }

    private handleEvent(type: string, event: any): void {
        if (type === 'mousedown') {
            this.lastMouseDownEvent = event;
            this.updateForMouseDown();
        } else if (type === 'mouseup') {
            this.lastMouseUpEvent = event;
            this.updateForMouseUp();
        } else if (type === 'mousemove') {
            this.lastMouseMoveEvent = event;
            this.updateForMouseMove();
        }

        if (this.mousedown && this.mouseMovedWhileClicked) {
            this.tryEmitDragStart(this.lastMouseDownEvent);
            return;
        }

        if (this.mouseup && this.mouseMovedWhileClicked) {
            this.tryEmitDragEnd(this.lastMouseUpEvent);
            return;
        }

        if (this.mouseup && !this.mouseMovedWhileClicked) {
            this.tryEmitClick(this.lastMouseMoveEvent);
            return;
        }
    }

    private updateForMouseDown() {
        this.mousedown = true;
        this.mouseup = false;
    }

    private updateForMouseUp() {
        this.mousedown = false;
        this.mouseup = true;

        // Mouse was released, reset moved on clicked flag
        if (this.mouseMovedWhileClicked) {
            this.mouseMovedWhileClicked = false;
        }
    }

    private updateForMouseMove() {
        this.mousemove = true;

        // Mouse was moved while down
        if (this.mousedown) {
            this.mouseMovedWhileClicked = true;
        }
    }

    private tryEmitClick(event: MouseEvent): void {
        const mouse = new THREE.Vector2(
            (event.clientX / window.innerWidth) * 2 - 1,
            -(event.clientY / window.innerHeight) * 2 + 1
        );
        this.threeEvents.click$.next(mouse);
    }

    private tryEmitDragStart(event: any): void {
        this.threeEvents.click$.next(event);
    }

    private tryEmitDragEnd(event: any): void {
        this.threeEvents.click$.next(event);
    }
}
