import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadStatus } from '../model/classes/load-status';

@Injectable({
  providedIn: 'root'
})
export class ProcessingOverlayService {

  activateProcessingOverlay$ = new BehaviorSubject<LoadStatus>(new LoadStatus());
  failed$ = new BehaviorSubject<LoadStatus>(new LoadStatus());
  progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() {}

  activateOverlay() {
    this.activateProcessingOverlay$.next(new LoadStatus(true));
  }

  deactivateOverlay() {
    this.activateProcessingOverlay$.next(new LoadStatus(false));
  }

  setFailed() {
    this.failed$.next(new LoadStatus(true));
  }

  hasFailed$(): Observable<boolean> {
    return this.failed$.pipe(
      map(o => o.status)
    );
  }

  isLoading$(): Observable<boolean> {
    return this.activateProcessingOverlay$.pipe(
      map(o => o.status)
    );
  }

  getTitle$(): Observable<string> {
    return this.activateProcessingOverlay$.pipe(
      map(o => o.title)
    );
  }

  getMessage$(): Observable<string> {
    return this.activateProcessingOverlay$.pipe(
      map(o => o.msg)
    );
  }

  setLoadProgress(progress: number) {
    this.progress$.next(progress > 100 ? 100 : progress);
  }
}
