import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { ProjectViewComponent } from './components/project-view/project-view.component';
import { RelationNetworkViewComponent } from './components/relation-network-view/relation-network-view.component';
import { SettingsViewComponent } from './components/settings-view/settings-view.component';
import { StoryEditorViewComponent } from './components/story-editor-view/story-editor-view.component';
import { PagePath } from './model/enum/pages';

const routes: Routes = [
  {
    path: 'env',
    redirectTo: `/${PagePath.HOME}`, // Replace with HomeComponent
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: `/${PagePath.HOME}`, // Replace with HomeComponent
    pathMatch: 'full'
  },
  {
    path: PagePath.HOME,
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: PagePath.PROJECT,
    pathMatch: 'full',
    component: ProjectViewComponent
  },
  {
    path: PagePath.STORY_EDITOR,
    pathMatch: 'full',
    component: StoryEditorViewComponent
  },
  {
    path: PagePath.RELATION_NETWORK,
    pathMatch: 'full',
    component: RelationNetworkViewComponent
  },
  {
    path: PagePath.MAP,
    pathMatch: 'full',
    component: MapViewComponent
  },
  {
    path: PagePath.SETTINGS,
    pathMatch: 'full',
    component: SettingsViewComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
