import { BehaviorSubject } from 'rxjs';
import { ProjectUpdateAction } from '../model/enum/project/project-update-action';
import { IProjectUpdate } from '../model/types/project/project-update';

// tslint:disable: max-line-length
export class ProjectEvents {
    public static projectUpdates$: BehaviorSubject<IProjectUpdate> = new BehaviorSubject<IProjectUpdate>(undefined);

    public static emitUpdate(path: string, action: ProjectUpdateAction, newVal?: any, prevVal?: any): void {
        ProjectEvents.projectUpdates$.next({ path: path, action: action, newVal: newVal, prevVal: prevVal });
    }

    /**
     * Parses paths in project updates
     * @param path - string - path following the format of "\<object-type\>(\<id\>).\<property-name\>". E.g. "project(w45h45hj-a4hya34h-a34ha43h-a4ha4h).character"
     * @returns - object - containing "object" thats changed, it's "id" and which "property" that was changed
     */
    public static parsePath(path: string): { object: string, id: string, property: string } {
        const object_rest_list = path.split('(');
        const id_rest_list = object_rest_list[1].split(')');
        const list = {
            object: object_rest_list[0],            // Anything before '('
            id: id_rest_list[0],                    // Anything before ')'
            property: id_rest_list[1].split('.')[1] // Anything after '.'
        };

        return list;
    }
}
