<div id='window_frame' cdkDragBoundary=".window-overlay" cdkDrag>
    <div id="window_titlebar" *ngIf="config.enable.titlebar">
        <span cdkDragHandle>{{config.title}}</span>
        <button mat-mini-fab id="maximize" class="button_gray" *ngIf="config.enable.maximize_minimize" [hidden]="maximized">
            <mat-icon id="maximize_icon" svgIcon="window_maximize"></mat-icon>
        </button>
        <button mat-mini-fab id="minimize" class="button_gray" *ngIf="config.enable.maximize_minimize" [hidden]="!maximized">
            <mat-icon id="minimize_icon" svgIcon="window_minimize"></mat-icon>
        </button>
        <button mat-mini-fab id="close" class="button" *ngIf="config.enable.close">
            <mat-icon id="close_icon" svgIcon="window_close"></mat-icon>
        </button>
    </div>
    <div id="window_content">
        <ng-template appWindowContent></ng-template>
        <div style="width: 30em; height: 30em;"></div>
    </div>
</div>

