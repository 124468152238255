import { Injectable, OnDestroy } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { IResizeInfo } from 'src/app/model/types/3dview/resize-info';
import { CanvasStore } from 'src/app/public/canvas-store';
import { mainCameraConfig } from 'src/app/model/config/3dview/main-camera-config';
import { Type } from 'src/app/utils/type';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { View3DEventsService } from './view3d-events.service';
import { CameraType } from 'src/app/model/enum/3dview/camera-type';
//import { CameraHandlerService } from './camera-handler.service';

@Injectable({
    providedIn: 'root'
})
export class InputHandlerService implements OnDestroy {

    public readonly threeID$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    get ID(): string {
        return this.threeID$.getValue();
    }

    public canvas: HTMLCanvasElement;
    public canvasParent: HTMLElement;

    private controls: OrbitControls;
    private mainCamera: THREE.PerspectiveCamera;

    perspective: CameraType;

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        //private cameraHandler: CameraHandlerService,
        private threeEvents: View3DEventsService
    ) {
        this.threeEvents.canvasFetched$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterFalse(),
            take(1)
       ).subscribe((_: any) => {
            this.canvas = CanvasStore.getCanvas(this.ID);
            this.canvasParent = CanvasStore.getCanvasParent(this.ID);
        });

        this.threeEvents.cameraMade$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterFalse(),
            take(1)
       ).subscribe((_: any) => {
            this.controls = new OrbitControls(
                //this.cameraHandler.mainCamera,
                this.mainCamera,
                this.canvas
            );
        });

        this.threeID$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull()
        ).subscribe((id: string) => {
            const canvas = CanvasStore.getCanvas(id);
            const canvasParent = CanvasStore.getCanvasParent(id);

            this.mainCamera = new THREE.PerspectiveCamera(
                mainCameraConfig.fov,
                canvasParent.clientWidth / canvasParent.clientHeight,
                mainCameraConfig.near,
                mainCameraConfig.far
            );
            this.mainCamera.position.set(0, 0, 5);

            this.controls = new OrbitControls(
                this.mainCamera,
                canvas
            );
            this.controls.maxDistance = 5;
            this.controls.autoRotate = true;

            RenderTrigger.enableRenderingLoop$.pipe(
                takeUntil(this.destroy$)
            ).subscribe((enabled: boolean) => {
                if (enabled) {
                    this.controls.removeEventListener('change', this.controlsChangeCB.bind(this));
                } else {
                    this.controls.addEventListener('change', this.controlsChangeCB.bind(this));
                }
            });

            RenderTrigger.resize$.pipe(
                takeUntil(this.destroy$)
            ).subscribe((_: string) => {
                this.mainCamera.aspect = canvasParent.clientWidth / canvasParent.clientHeight;
                this.mainCamera.updateProjectionMatrix();
            });
        });

        this.destroy$.pipe(take(1)).subscribe((value: boolean) => {
            this.controls.dispose();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    private controlsChangeCB(): void {
        this.threeID$.pipe(
            takeUntil(this.destroy$),
            RXJSUtils.filterUndefinedAndNull(),
            take(1)
        ).subscribe((id: string) => {
            RenderTrigger.render$.next(id);
        });
    }

    /* -------------------------------- Controls -------------------------------- */

    resetControls(): void {
        if (!this.controls) {
            return;
        }

        this.controls.reset();
    }

    updateControls(): void {
        if (!this.controls) {
            return;
        }

        this.controls.update();
    }

    activateControls(): void {
        if (!this.controls) {
            return;
        }

        this.controls.enableKeys = true;
    }

    deActivateControls(): void {
        if (!this.controls) {
            return;
        }

         this.controls.enableKeys = false;
    }

    enablePan(): void {
        if (!this.controls) {
            return;
        }

        this.controls.enablePan = true;
    }

    disablePan(): void {
        if (!this.controls) {
            return;
        }

        this.controls.enablePan = false;
    }

    enableRotate(): void {
        if (!this.controls) {
            return;
        }

        this.controls.enableRotate = true;
    }

    disableRotate(): void {
        if (!this.controls) {
            return;
        }

        this.controls.enableRotate = false;
    }

    disposeControls(): void {
        if (!this.controls) {
            return;
        }

        this.controls.dispose();
    }



    setCameraTarget(pos: THREE.Vector3): void {
        if (!pos) {
            this.resetCameraTarget();
        } else {
            this.controls.target.copy(pos);
            this.updateControls();
        }
    }

    resetCameraTarget(): void {
        this.controls.target.set(0, 0, 0);
        this.updateControls();
    }

    setMaxCameraDistance(dist: number) {
        this.controls.maxDistance = dist;
    }

/* ----------------------------- Camera ---------------------------- */

    getMainCamera(): THREE.PerspectiveCamera {
        return this.mainCamera;
    }

    setAspect(aspect: number): void {
        this.mainCamera.aspect = aspect;
    }
}
